import React from 'react';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { Page, Header, Content, ContentHeader, SupportButton, InfoCard } from '@backstage/core-components';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    overviewCard: {
        marginBottom: theme.spacing(4),
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    componentCard: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    gridContainer: {
        width: '100%',
    },
    linkContainer: {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
    },
    link: {
        color: theme.palette.common.white,
        '&:hover': {
            textDecoration: 'none',
            opacity: 0.8,
        },
    },
    carSalesCard: {
        backgroundColor: '#1769aa',
    },
    myPorscheCard: {
        backgroundColor: '#357a38',
    },
    digitalProductsCard: {
        backgroundColor: '#6d1b7b',
    },
    bamCard: {
        backgroundColor: '#b28704',
    },
}));

const resourcesData = [
    {
        title: 'Car Sales',
        description: 'Structurizr workspace for Car Sales.',
        links: [
            { title: 'View Architecture', url: 'https://structurizr.slsystem.aws.porsche.cloud/workspace/1' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/slsystem-structurizr-workspaces/tree/main/workspaces/1' },
        ],
        className: 'carSalesCard',
    },
    {
        title: 'My Porsche',
        description: 'Structurizr workspace for My Porsche.',
        links: [
            { title: 'View Architecture', url: 'https://structurizr.slsystem.aws.porsche.cloud/workspace/3' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/slsystem-structurizr-workspaces/tree/main/workspaces/3' },
        ],
        className: 'myPorscheCard',
    },
    {
        title: 'Connect Store - Digital Products',
        description: 'Structurizr workspace for the Connect Store - Digital Products.',
        links: [
            { title: 'View Architecture', url: 'https://structurizr.slsystem.aws.porsche.cloud/workspace/6' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/slsystem-structurizr-workspaces/tree/main/workspaces/6' },
        ],
        className: 'digitalProductsCard',
    },
    {
        title: 'BaM Brand and Model',
        description: 'Structurizr workspace for BaM Brand and Model.',
        links: [
            { title: 'View Architecture', url: 'https://structurizr.slsystem.aws.porsche.cloud/workspace/2' },
            { title: 'GitHub', url: 'https://github.com/porsche-customer/slsystem-structurizr-workspaces/tree/main/workspaces/2' },
        ],
        className: 'bamCard',
    },
];

export const ArchitectureDiagramsOverview: React.FC = () => {
    const classes = useStyles();

    return (
        <Page themeId='home'>
            <Header title='Architecture Diagrams' subtitle='Software architecture diagrams as code' />
            <Content>
                <ContentHeader title=''>
                    <SupportButton>Need help with architecture diagrams? Contact the Architecture team.</SupportButton>
                </ContentHeader>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <InfoCard title='About Architecture Diagrams' className={classes.overviewCard}>
                            <Typography variant='body1'>
                                We recommend each team and ART to use Structurizr for Architecture Diagrams. Structurizr is a collection of
                                tools to create software architecture diagrams and documentation as code. It is based on the C4 model and
                                allows you to create multiple diagrams from the same model, ensuring consistency across all representations
                                of your system.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    {resourcesData.map((resource) => (
                        <Grid item xs={12} md={6} key={resource.title}>
                            <InfoCard
                                title={resource.title}
                                className={`${classes.componentCard} ${classes[resource.className as keyof typeof classes]}`}
                            >
                                <Typography variant='body2' style={{ marginBottom: '16px' }}>
                                    {resource.description}
                                </Typography>
                                <div className={classes.linkContainer}>
                                    {resource.links.map((link) => (
                                        <Button
                                            key={link.url}
                                            variant='outlined'
                                            className={classes.link}
                                            href={link.url}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {link.title}
                                        </Button>
                                    ))}
                                </div>
                            </InfoCard>
                        </Grid>
                    ))}
                </Grid>
            </Content>
        </Page>
    );
};
