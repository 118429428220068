import { createBaseThemeOptions, createUnifiedTheme, genPageTheme, palettes, shapes } from '@backstage/theme';
import PorscheNextRegular from '../assets/fonts/PorscheNextWLa-Regular.woff2';

const PorscheNextRegularFont = {
    fontFamily: 'PorscheNextRegularFont',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
        local('PorscheNextRegularFont'),
        url(${PorscheNextRegular}) format('woff2'),
        `,
};

export const customTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
        palette: {
            ...palettes.dark,
        },
    }),

    defaultPageTheme: 'home',
    fontFamily: PorscheNextRegularFont.fontFamily,
    /* below drives the header colors */
    pageTheme: {
        home: genPageTheme({ colors: ['#000000', '#D5001C'], shape: shapes.wave }),
        documentation: genPageTheme({
            colors: ['#000000', '#D5001C'],
            shape: shapes.wave2,
        }),
    },
});
