import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Page, Header, Content, ContentHeader, SupportButton, InfoCard } from '@backstage/core-components';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DomainIcon from '@material-ui/icons/Domain';
import BuildIcon from '@material-ui/icons/Build';
import DescriptionIcon from '@material-ui/icons/Description';
import SpeedIcon from '@material-ui/icons/Speed';
import ApiIcon from '@material-ui/icons/Speed';
import ArchitectureIcon from '@material-ui/icons/Speed';
import CodeIcon from '@material-ui/icons/Code';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    overviewCard: {
        marginBottom: theme.spacing(4),
        width: '100%',
        backgroundColor: theme.palette.background.default,
    },
    componentCard: {
        height: '100%',
        width: '100%',
    },
    cardContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    gridContainer: {
        width: '100%',
    },
    icon: {
        fontSize: 40,
        marginBottom: theme.spacing(1),
    },
    systemsCard: {
        backgroundColor: '#093170',
    },
    domainsCard: {
        backgroundColor: '#1769aa',
    },
    adrCard: {
        backgroundColor: '#008394',
    },
    faastCard: {
        backgroundColor: '#00695f',
    },
    techRadarCard: {
        backgroundColor: '#357a38',
    },
    apiGuideCard: {
        backgroundColor: '#482880',
    },
    structurizrCard: {
        backgroundColor: '#b28704',
    },
    apiCard: {
        backgroundColor: '#aa2e25',
    },
    componentsCard: {
        backgroundColor: '#0276aa',
    },
    architectureDiagramsCard: {
        backgroundColor: '#b28704',
    },
}));


const HomePage = () => {
    const classes = useStyles();
    const configApi = useApi(configApiRef);
    const appTitle = configApi.getString('app.title');

    const cardData = [
        {
            title: 'Systems',
            className: classes.systemsCard,
            deepLink: { title: 'Explore Systems', link: '/catalog?filters[kind]=system' },
            icon: <AccountTreeIcon className={classes.icon} />,
            description:
                'Systems represent the high-level components of your software ecosystem. They help in organizing and managing large-scale architectures.',
        },
        {
            title: 'Domains',
            className: classes.domainsCard,
            deepLink: { title: 'Explore Domains', link: '/catalog?filters[kind]=domain' },
            icon: <DomainIcon className={classes.icon} />,
            description:
                'Domains group together entities that share a common purpose or business function, helping to organize your software catalog.',
        },
        {
            title: 'Components',
            className: classes.componentsCard,
            deepLink: { title: 'Explore Components', link: '/catalog?filters[kind]=component' },
            icon: <BuildIcon className={classes.icon} />,
            description:
                'Components are the individual building blocks of your software architecture. They can represent services, libraries, or other reusable parts of your systems.',
        },
        {
            title: 'ADR',
            className: classes.adrCard,
            deepLink: { title: 'View ADRs', link: '/adr-overview' },
            icon: <DescriptionIcon className={classes.icon} />,
            description:
                'Architectural Decision Records (ADRs) document important architectural decisions made along with their context and consequences.',
        },
        {
            title: 'FAAST',
            className: classes.faastCard,
            deepLink: { title: 'FAAST Overview', link: '/faast-overview' },
            icon: <SpeedIcon className={classes.icon} />,
            description:
                'Framework of Agile Assurance Skills Template (FAAST) provides a structured approach to ensure agile development practices and skills.',
        },
        {
            title: 'Tech Radar',
            className: classes.techRadarCard,
            deepLink: { title: 'Tech Radar Overview', link: '/tech-radar-overview' },
            icon: <SpeedIcon className={classes.icon} />,
            description:
                'The Tech Radar provides an overview of various technologies, frameworks, tools, and platforms used or being evaluated in your organization.',
        },
        {
            title: 'API Guide',
            className: classes.apiGuideCard,
            deepLink: { title: 'API Guide', link: '/api-guide' },
            icon: <ApiIcon className={classes.icon} />,
            description:
                'The API Guide provides best practices, standards, and guidelines for designing, developing, and consuming APIs within your organization.',
        },
        {
            title: 'Architecture Diagrams',
            className: classes.architectureDiagramsCard,
            deepLink: { title: 'Architecture Diagrams Overview', link: '/architecture-diagrams' },
            icon: <ArchitectureIcon className={classes.icon} />,
            description:
                'Create and manage software architecture diagrams using various tools and methodologies. Visualize your system designs consistently across all representations.',
        },
        {
            title: 'APIs',
            className: classes.apiCard,
            deepLink: { title: 'Explore APIs', link: '/catalog?filters[kind]=api' },
            icon: <CodeIcon className={classes.icon} />,
            description:
                'Explore and manage the APIs in your organization. This includes API specifications, documentation, and integration details for various services and systems.',
        },
    ];

    return (
        <Page themeId='home'>
            <Header title={appTitle} subtitle='Developer Portal for Porsche Products build on Backstage.io' />
            <Content>
                <ContentHeader title=''>
                    <SupportButton>Need help? Contact the Backstage team.</SupportButton>
                </ContentHeader>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <InfoCard title='About Backstage' className={classes.overviewCard}>
                            <Typography variant='body1'>
                                Backstage is an open-source platform for building developer portals. It's based on a centralized software
                                catalog and offers a rich set of tools for software development lifecycle.
                            </Typography>
                        </InfoCard>
                    </Grid>
                    {cardData.map((card, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <InfoCard title={card.title} className={`${classes.componentCard} ${card.className}`} deepLink={card.deepLink}>
                                {card.icon}
                                <Typography variant='body2'>{card.description}</Typography>
                            </InfoCard>
                        </Grid>
                    ))}
                </Grid>
            </Content>
        </Page>
    );
};

export default HomePage;
