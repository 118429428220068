import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from '@backstage/plugin-catalog';
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage } from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { TechRadarOverview } from './components/tech-radar-overview/TechRadarOverview';
import { FaastOverview } from './components/faast-overview/FaastOverview';
import { ADROverview } from './components/adr-overview/ADROverview';
import { ArchitectureDiagramsOverview } from './components/architecture-diagrams/ArchitectureDiagramsOverview';
import { UnifiedThemeProvider, themes } from '@backstage/theme';
import { customTheme } from './theme/customTheme';
import { CssBaseline } from '@material-ui/core';

import { AlertDisplay, OAuthRequestDialog, SignInProviderConfig } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { SignInPage } from '@backstage/core-components';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { ApiGuide } from './components/api-guide';
import HomePage from './components/HomePage';

const host = window.location.host;
const providers: ('custom' | SignInProviderConfig | 'guest')[] = [];
if (host.includes('localhost')) {
    providers.push('guest');
}

providers.push({
    id: 'microsoft-auth-provider',
    title: 'AzureAD',
    message: 'Sign in using Azure AD',
    apiRef: microsoftAuthApiRef,
});

const app = createApp({
    apis,
    components: {
        SignInPage: (props) => <SignInPage {...props} auto providers={providers} />,
    },
    bindRoutes({ bind }) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
            createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
    themes: [
        {
            id: 'dark',
            title: 'Dark',
            variant: 'dark',
            Provider: ({ children }) => <UnifiedThemeProvider theme={themes.dark} children={children} />,
        },
        {
            id: 'pdigTheme',
            title: 'Custom Theme',
            variant: 'dark',
            Provider: ({ children }) => (
                <UnifiedThemeProvider theme={customTheme} noCssBaseline>
                    <CssBaseline />
                    {children}
                </UnifiedThemeProvider>
            ),
        },
    ],
});

const routes = (
    <FlatRoutes>
        <Route path='/' element={<HomePage />} />
        <Route path='/catalog' element={<CatalogIndexPage />} />
        <Route path='/catalog/:namespace/:kind/:name' element={<CatalogEntityPage />}>
            {entityPage}
        </Route>
        <Route path='/docs' element={<TechDocsIndexPage />} />
        <Route path='/docs/:namespace/:kind/:name/*' element={<TechDocsReaderPage />}>
            <TechDocsAddons>
                <ReportIssue />
            </TechDocsAddons>
        </Route>
        <Route path='/create' element={<ScaffolderPage />} />
        <Route path='/api-docs' element={<ApiExplorerPage />} />
        <Route path='/api-guide' element={<ApiGuide />} />
        <Route path='/tech-radar-overview' element={<TechRadarOverview />} />
        <Route path='/faast-overview' element={<FaastOverview />} />
        <Route
            path='/catalog-import'
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage />
                </RequirePermission>
            }
        />
        <Route path='/search' element={<SearchPage />}>
            {searchPage}
        </Route>
        <Route path='/settings' element={<UserSettingsPage />} />
        <Route path='/catalog-graph' element={<CatalogGraphPage />} />
        <Route path='/adr' element={<ADROverview />} />
        <Route path='/architecture-diagrams' element={<ArchitectureDiagramsOverview />} />
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay />
        <OAuthRequestDialog />
        <AppRouter>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
